var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "containers" }, [
    _c("div", { ref: "canvas", staticClass: "canvas" }),
    _c("div", { staticClass: "panel", attrs: { id: "js-properties-panel" } }),
    _c("ul", { staticClass: "buttons" }, [
      _c("li", [_vm._v("下载")]),
      _c("li", [
        _c(
          "a",
          {
            ref: "saveDiagram",
            attrs: { href: "javascript:", title: "download BPMN diagram" },
          },
          [_vm._v("BPMN diagram")]
        ),
      ]),
      _c("li", [
        _c(
          "a",
          {
            ref: "saveSvg",
            attrs: { href: "javascript:", title: "download as SVG image" },
          },
          [_vm._v("SVG image")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }